import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

import Layout from "~/components/Layout";
import { Colors, FontFamily } from "~/components/ThemeVariables";
import { breakpointIpad } from "~/components/styleUtils";
import ExternalLink from "~/components/common/ExternalLink";
import PrimaryButton from "~/components/common/PrimaryButton";
import externalLinks from "~/data/dclimate.json";
import internalLinks from "~/data/sitemap.json";

const Title = styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;

    ${breakpointIpad(`
        font-size: 28px;
        line-height: 33px;
    `)}

    color: ${Colors.WHITE};
`;

const CenterProductLayout = styled.div`
    display: flex;
    justify-content: center;
`;

const ProductLayout = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    row-gap: 80px;
    column-gap: 100px;

    max-width: 2000px;

    margin: 0 30px;
    padding-top: 20px;
    padding-bottom: 200px;
`;

const Section = styled.section`
    max-width: 473px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ImgContainer = styled.div`
    padding: 40px 0px;
    max-width: 935px;
`;

const P = styled.p`
    font-weight: 300;
    font-size: 18px;

    color: ${Colors.WHITE};

    ${breakpointIpad(`
        font-size: 16px;
    `)}

    padding-bottom: 26px;
`;

const ComingSoon = styled.p`
    font-family: ${FontFamily.SOURCE};
    font-weight: 500;
    font-size: 16px;

    color: ${Colors.ELECTRIC_BLUE};
`;

interface ProductProps {
    title: string;
    staticImg: JSX.Element;
    description?: string;
    link: string;
    internalLink?: boolean;
    buttonText?: string;
}

const Product = ({
    title,
    staticImg,
    description,
    link,
    internalLink,
    buttonText,
}: ProductProps): JSX.Element => (
    <Section>
        <Title>{title}</Title>
        <ImgContainer>
            {/* Gatsby has to know static image sources at build time, so we pass in an entire <StaticImage> component instead of building it here */}
            {staticImg}
        </ImgContainer>
        {description && <P>{description}</P>}
        {/*  Nested expression is cleaner than the alternatives tried. */}
        {/* eslint-disable-next-line no-nested-ternary */}
        {buttonText ? (
            internalLink ? (
                <Link to={link}>
                    <PrimaryButton>{buttonText}</PrimaryButton>
                </Link>
            ) : (
                <ExternalLink to={link}>
                    <PrimaryButton>{buttonText}</PrimaryButton>
                </ExternalLink>
            )
        ) : (
            <ComingSoon>Coming Soon</ComingSoon>
        )}
    </Section>
);

const ProductsPage = (): JSX.Element => (
    <>
        <Helmet>
            <title>dClimate - Products</title>

            <meta
                name="description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />

            <meta property="og:title" content="dClimate - Products" />
            <meta property="og:type" content="website" />

            <meta property="og:url" content="https://dclimate.net" />
            <meta
                name="og:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1200x630.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1920x960.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="960" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1920x1080.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="1080" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />
            <meta property="og:site_name" content="dClimate" />

            <meta
                property="og:video"
                content="https://www.youtube.com/watch?v=nHOXUVYwWL4"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="dClimate - Products" />
            <meta
                name="twitter:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta
                name="twitter:image"
                content="https://dclimate.net/img/meta/twitter_summary_card.png"
            />
            <meta name="twitter:image:alt" content="get data. share data." />
        </Helmet>
        <Layout>
            <CenterProductLayout>
                <ProductLayout>
                    <Product
                        title="Data Marketplace"
                        staticImg={
                            <StaticImage
                                src="../../components/products/marketplace-app.png"
                                alt="Marketplace app screenshot"
                                placeholder="blurred"
                            />
                        }
                        description="The dClimate Data Marketplace is your one-stop shop for all things climate data. Here you can browse climate data from various verified sources. You can also upload your own data with the option to monetize it. "
                        link={externalLinks.product.marketplace}
                        buttonText="Launch Marketplace"
                    />
                    <Product
                        title="CYCLOPS"
                        staticImg={
                            <StaticImage
                                src="../../components/products/cyclops-app.png"
                                alt="Cyclops app screenshot"
                                placeholder="blurred"
                            />
                        }
                        description="Discover CYCLOPS, an innovative digital Measurement, Reporting, and Verification (dMRV) platform engineered to reshape the monitoring and management of natural capital and carbon markets globally."
                        link={externalLinks.product.cyclops}
                        buttonText="Visit Site"
                    />
                    <Product
                        title="Aegis"
                        staticImg={
                            <StaticImage
                                src="../../components/products/aegis-app.png"
                                alt="Aegis app screenshot"
                                placeholder="blurred"
                            />
                        }
                        description="Aegis is a cutting-edge physical climate risk assessment tool that provides detailed and actionable insights to businesses, helping them understand and mitigate the financial risks associated with climate change."
                        internalLink
                        link={internalLinks.aegis}
                        buttonText="Learn More"
                    />
                    {
                        /*
                    <Product
                        title="CO2.0 "
                        staticImg={
                            <StaticImage
                                src="../../components/products/co2.png"
                                alt="CO2 app screenshot"
                                placeholder="blurred"
                            />
                        }
                        description="CO2.0 is a blockchain carbon offset platform that pays countries to save millions of acres of tropical forests. Its main focus is to preserve at-risk tropical rainforests by preventing deforestation."
                        link='"'
                    /> */
                        <Product
                            title="Siren"
                            staticImg={
                                <StaticImage
                                    src="../../components/products/siren-app.png"
                                    alt="Siren app screenshot"
                                    placeholder="blurred"
                                />
                            }
                            description="Siren is a dynamic climate data analytics platform, designed for today’s forward-thinking agricultural professionals. It combines historical weather data, advanced forecasting, and detailed yield analysis."
                            internalLink
                            link={internalLinks.siren}
                            buttonText="Learn More"
                        />
                    }
                    {/*
                    <Product
                        title="dClimate REST API"
                        staticImg={
                            <StaticImage
                                src="../../components/products/api.png"
                                alt="API screenshot"
                                placeholder="blurred"
                            />
                        }
                        description="Enjoy unlimited access to all the free data hosted on the dClimate API."
                        link={externalLinks.dev.api}
                        buttonText="Launch API"
                    /> 
                    */}
                    {/*
                    <Product
                        title="WorkSight"
                        staticImg={
                            <StaticImage
                                src="../../components/products/construction.png"
                                alt="Construction App screenshot"
                                placeholder="blurred"
                            />
                        }
                        link={externalLinks.product.construction}
                        buttonText="Launch WorkSight"
                    />
                    */}
                </ProductLayout>
            </CenterProductLayout>
        </Layout>
    </>
);

export default ProductsPage;
